import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 4000.000000 3000.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,3000.000000) scale(0.100000,-0.100000)">

<path d="M23239 25386 c-2 -2 -69 -6 -149 -10 -341 -14 -893 -141 -1018 -233
-159 -118 -176 -333 -38 -481 65 -69 143 -103 238 -103 59 0 97 8 198 41 180
59 351 96 531 115 30 4 68 8 84 11 60 8 443 4 540 -6 988 -106 1903 -698 2413
-1560 239 -406 377 -873 397 -1345 8 -186 28 -254 95 -326 67 -71 144 -104
245 -104 60 0 91 6 126 22 63 29 125 87 157 147 27 50 27 54 26 241 0 231 -22
413 -78 665 -147 660 -499 1284 -1003 1783 -262 258 -477 426 -771 600 -109
65 -369 200 -447 232 -89 37 -285 110 -355 132 -176 57 -506 130 -665 148 -27
3 -59 8 -70 10 -42 8 -449 27 -456 21z"/>
<path d="M22965 24340 c-263 -36 -448 -86 -683 -185 -144 -61 -201 -104 -241
-187 -84 -169 -1 -381 174 -448 102 -39 173 -32 332 34 146 60 301 108 438
134 130 24 531 24 665 -1 805 -150 1479 -740 1698 -1487 66 -225 82 -359 76
-642 l-4 -217 34 -64 c38 -74 79 -116 151 -155 44 -24 61 -27 145 -27 109 0
160 19 224 83 90 89 112 199 103 521 -6 226 -24 371 -72 561 -117 465 -360
892 -712 1250 -57 58 -131 127 -164 155 -34 27 -67 54 -73 60 -25 24 -137 106
-221 162 -243 161 -506 284 -785 367 -322 96 -759 131 -1085 86z"/>
<path d="M22860 23293 c-169 -8 -371 -46 -550 -105 -127 -41 -179 -69 -223
-117 -55 -61 -70 -97 -75 -184 -4 -68 -1 -87 22 -145 48 -123 158 -208 284
-219 48 -4 76 1 178 35 147 49 249 71 374 81 597 47 1197 -295 1475 -840 104
-205 142 -358 154 -620 9 -201 21 -241 98 -315 132 -128 369 -127 482 2 62 70
74 114 77 264 6 309 -69 632 -215 925 -80 161 -142 259 -249 394 -169 212
-379 396 -610 532 -238 141 -529 248 -786 289 -102 16 -320 28 -436 23z"/>
<path d="M14710 17488 l0 -5233 3292 2 c1811 1 3340 5 3398 8 58 3 143 11 190
17 47 6 92 10 100 10 30 1 336 58 437 83 324 78 735 245 1040 423 344 200 687
476 940 754 100 110 140 157 235 278 177 226 397 601 509 870 104 248 198 556
244 800 61 319 70 423 70 790 -1 324 -3 356 -29 530 -28 190 -90 485 -127 600
-11 36 -22 72 -24 80 -21 80 -131 356 -198 498 -215 455 -504 853 -877 1207
-140 134 -406 352 -416 342 -2 -2 5 -62 15 -133 39 -265 46 -373 45 -729 0
-263 -4 -388 -17 -505 -34 -298 -78 -525 -152 -786 l-35 -120 49 -123 c118
-290 168 -586 158 -936 -8 -296 -45 -497 -134 -745 -47 -129 -143 -339 -185
-400 -12 -19 -41 -64 -63 -100 -21 -36 -43 -69 -47 -75 -5 -5 -36 -46 -71 -91
-195 -252 -413 -444 -697 -612 -215 -127 -477 -228 -715 -276 -223 -45 -251
-46 -1615 -46 l-1300 0 0 400 0 400 68 0 c274 1 620 48 946 131 470 118 963
351 1359 641 321 235 644 557 871 868 306 418 525 877 645 1345 92 360 114
506 131 848 13 278 -10 681 -50 882 -6 28 -12 59 -14 70 -140 693 -414 1271
-859 1815 -107 130 -437 454 -577 566 -209 166 -531 375 -690 447 -30 14 -98
45 -150 70 -143 68 -208 93 -405 159 -178 59 -400 117 -525 137 -36 6 -76 13
-90 16 -77 15 -265 36 -405 45 -98 6 -962 10 -2217 10 l-2058 0 0 -5232z
m4380 3599 c512 -86 935 -294 1298 -639 165 -157 315 -340 419 -513 54 -90
180 -363 212 -460 41 -127 94 -352 111 -470 49 -363 -3 -808 -136 -1157 -222
-583 -660 -1061 -1217 -1328 -220 -106 -375 -155 -667 -211 -113 -22 -131 -23
-1052 -26 l-938 -4 0 -1204 0 -1205 -400 0 -400 0 0 3620 0 3621 1333 -4
c1216 -3 1341 -4 1437 -20z"/>
<path d="M17120 18695 l0 -1605 801 -2 c440 -2 803 -1 805 1 2 2 4 366 4 808
l0 803 806 0 806 0 -6 98 c-3 53 -7 104 -9 112 -2 8 -7 38 -11 65 -31 204
-125 436 -248 618 -46 67 -50 73 -130 168 -173 204 -446 380 -733 472 -205 66
-221 67 -1204 67 l-881 0 0 -1605z"/>
<path d="M22960 21970 c-209 -56 -378 -201 -454 -392 -97 -244 -47 -521 129
-716 67 -73 127 -118 220 -165 107 -54 182 -71 315 -71 141 -1 221 21 343 94
431 254 433 868 4 1150 -123 82 -215 110 -367 116 -93 3 -129 1 -190 -16z"/>
<path d="M20735 11950 c-284 -45 -473 -212 -507 -449 -26 -177 50 -343 209
-452 57 -39 182 -100 273 -133 110 -39 209 -96 244 -140 27 -33 31 -45 31
-101 0 -71 -17 -103 -75 -143 -50 -34 -106 -46 -210 -45 -112 1 -207 20 -331
67 -49 18 -91 31 -93 27 -2 -3 -20 -75 -41 -160 -31 -127 -36 -155 -24 -162
27 -17 165 -59 243 -74 390 -75 752 37 878 271 78 144 78 350 1 483 -73 125
-177 197 -438 300 -204 81 -275 138 -275 224 0 165 237 222 524 126 49 -16 90
-28 92 -27 11 12 84 302 77 309 -14 14 -116 48 -196 65 -88 19 -301 26 -382
14z"/>
<path d="M15050 11933 c-88 -5 -282 -24 -296 -30 -12 -4 -14 -150 -14 -864 l0
-859 195 0 195 0 0 314 0 314 83 -5 c244 -16 497 59 626 185 207 203 213 572
12 773 -146 145 -379 195 -801 172z m374 -307 c70 -21 121 -59 153 -113 24
-42 28 -58 28 -128 -1 -62 -6 -89 -22 -121 -61 -115 -213 -177 -388 -159 l-65
7 0 258 0 259 23 4 c55 11 227 6 271 -7z"/>
<path d="M19200 11934 c-105 -7 -285 -25 -302 -30 -17 -5 -18 -55 -18 -865 l0
-859 195 0 195 0 0 345 0 345 86 0 c110 0 163 -14 212 -56 50 -43 73 -97 122
-292 41 -159 59 -223 87 -299 l16 -43 203 0 203 0 -14 33 c-7 18 -43 140 -78
272 -36 132 -75 264 -86 292 -38 96 -94 166 -169 211 l-33 19 56 27 c156 77
255 238 255 416 0 247 -151 409 -435 466 -69 14 -387 25 -495 18z m387 -308
c63 -22 100 -54 128 -110 48 -96 30 -205 -45 -278 -63 -60 -119 -78 -278 -85
l-122 -6 2 244 3 244 40 7 c63 10 226 1 272 -16z"/>
<path d="M23730 11933 c-88 -5 -282 -24 -296 -30 -12 -4 -14 -150 -14 -864 l0
-859 195 0 195 0 0 314 0 314 83 -5 c244 -16 497 59 626 185 207 203 213 572
12 773 -146 145 -379 195 -801 172z m374 -307 c70 -21 121 -59 153 -113 24
-42 28 -58 28 -128 -1 -62 -6 -89 -22 -121 -61 -115 -213 -177 -388 -159 l-65
7 0 258 0 259 23 4 c55 11 227 6 271 -7z"/>
<path d="M16100 11055 l0 -875 757 2 756 3 61 220 61 220 252 3 252 2 30 -97
c16 -54 47 -155 68 -225 l37 -128 212 0 212 0 -22 73 c-12 39 -134 433 -271
875 l-249 802 -259 -2 -260 -3 -256 -838 -256 -838 -3 125 -3 126 -359 0 -360
0 0 210 0 210 320 0 320 0 0 160 0 160 -320 0 -320 0 0 185 0 185 340 0 340 0
0 160 0 160 -540 0 -540 0 0 -875z m1924 430 c19 -77 62 -232 96 -345 33 -113
60 -208 60 -212 0 -5 -85 -8 -190 -8 -157 0 -190 2 -190 14 0 8 25 103 55 213
31 109 69 257 86 328 32 140 37 160 44 154 2 -2 19 -67 39 -144z"/>
<path d="M22285 11918 c-16 -46 -525 -1720 -525 -1728 0 -7 68 -9 202 -8 l201
3 61 220 61 220 252 3 252 2 30 -97 c16 -54 47 -155 68 -225 l37 -128 212 0
212 0 -22 73 c-12 39 -134 433 -271 875 l-249 802 -258 0 c-198 0 -260 -3
-263 -12z m289 -433 c19 -77 62 -232 96 -345 33 -113 60 -208 60 -212 0 -5
-85 -8 -190 -8 -161 0 -190 2 -190 15 0 8 24 101 54 207 30 106 68 254 85 328
34 145 39 165 46 159 2 -2 19 -67 39 -144z"/>
<path d="M24780 11055 l0 -875 200 0 200 0 0 875 0 875 -200 0 -200 0 0 -875z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
